.EmploymentForm {
  .Grid {
    @apply grid grid-cols-1 gap-4 sm:grid-cols-2;
  }
  .RowsCoClient {
    @apply grid-rows-4 sm:grid-rows-2;
  }
  .RowsNoCoClient {
    @apply grid-rows-[0fr_repeat(3,auto)] sm:grid-rows-[0fr_repeat(2,auto)];
  }
  .EmployerName {
    @apply row-start-3 sm:row-start-2;
  }
  .AnnualEmploymentIncomeNoCoClient {
    @apply sm:col-span-2;
  }
}
