.AddButton {
  @apply flex flex-row items-center justify-center gap-2;
  @apply min-h-[104px] w-full min-w-[200px] py-2;
  @apply border border-input-primary;
  @apply hover:border-input-primary--hover;

  &Label {
    @apply text-body text-link;
  }
}
