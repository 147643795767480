@import "@earned/core/src/styles/scss/globals.scss";
@import "./components.scss";
@config "../../tailwind.config.js";

@font-face {
  font-family: "RedHat_Mono";
  src: url("./fonts/RedHat_Mono/font.ttf");
}

@font-face {
  font-family: "RedHat_Text";
  src: url("./fonts/RedHat_Text/font.ttf");
}

@layer base {
  html {
    @apply antialiased;
    font-family: RedHat_Text, system-ui, sans-serif;
    min-height: -webkit-fill-available;
  }
  body {
    min-height: -webkit-fill-available;
    overflow: hidden;
  }
  main {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    @media screen and (max-width: 767px) {
      padding-bottom: 72px;
    }
  }
  a {
    @apply focus-visible:outline-none;
  }
  button {
    @apply focus-visible:outline focus-visible:outline-2 focus-visible:outline-green-100/20;
  }
}

@layer components {
  .for-you-article-content {
    white-space: pre-wrap;
    p,
    a,
    li {
      @apply text-body font-thin sm:text-body-lg;
    }

    a {
      @apply font-normal underline;
    }

    li {
      @apply ml-3 list-disc;
    }

    img {
      @apply py-2;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @apply text-title-sm;
    }
  }

  .offset-mobile-bottom-padding-hack {
    @media screen and (max-width: 767px) {
      margin-bottom: -72px;
    }
  }
  .safari-only-screen-height {
    @supports (-webkit-touch-callout: none) {
      height: calc(100vh - 220px);
    }
  }
}

/* BOX */
.bp-ControlsLayer {
  opacity: unset !important;
}
.bp-ControlsBar {
  @apply flex h-9 flex-row bg-green-5 fill-green-5 stroke-green-5 font-sans font-medium text-green-5 shadow-sm;
}
.bp-ControlsBarGroup {
  @apply mx-0.5 h-full;
}
.bp-ControlsBarGroup:last-child {
  @apply mr-0.5;
}
.bp-ControlsBarGroup--distinct {
  @apply order-1 rounded-l-none border-l border-green-5 bg-green-5 px-2 py-0;
}
.bp-ZoomControls-current {
  @apply m-0 font-sans text-white;
}
.bp-PageControls {
  @apply text-white;
}
.bp-PageControlsForm-button-label {
  @apply font-sans tracking-wide text-white opacity-100;
}
.bp-PageControls-button:disabled {
  @apply opacity-100;
}
.bp-PageControls-button:disabled svg {
  @apply fill-gray-400 stroke-gray-400;
}
.bp-content {
  @apply bg-gray-50 font-sans;
}
.pdfViewer .page .canvasWrapper {
  @apply rounded border border-green-5 shadow-sm;
}
.pdfViewer .page .canvasWrapper canvas {
  @apply rounded;
}
.bp-doc .pdfViewer .page {
  @apply mx-auto my-8 rounded p-0;
}
.bp-navigate svg path {
  @apply bg-green-300 fill-green-300 stroke-green-300 text-green-300;
}
.bp-error > * {
  @apply font-sans;
}
.bp-error .bp-icon {
  @apply mb-4;
}
/* Zendesk */

@media screen and (min-width: 768px) {
  iframe[title$="Messaging window"] {
    top: -7px !important;
  }
}
@media screen and (max-width: 767px) {
  iframe[title$="Messaging window"] {
    top: 62px !important;
  }
}
iframe[title$="Messaging window"] {
  position: fixed !important;
  max-height: calc(100% + 7px) !important;
  height: calc(100% + 7px) !important;
  bottom: 0px !important;
  right: 0px !important;
  z-index: 40 !important;
  transition: left 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0.15s !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 300ms !important;
  transition-property: left, width, max-width !important;
  transition-delay: 0ms !important;
}
iframe[title*="launch messaging window"] {
  display: none;
}
iframe[title$="messages"] {
  display: none;
  bottom: 52px !important;
  left: 54px; // halfway between correct positions...
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 300ms !important;
  transition-property: left !important;
  transition-delay: 0ms !important;
  pointer-events: none;
}

/* Calendly */
.calendly-popup-close {
  right: 12px !important;
  top: 22px !important;
}
// media queries
// 975/976
// 649/650
.calendly-popup-content iframe {
  transform: scale(0.9);
  height: 100vh;
  position: fixed;
  top: 60px;
}
@media screen and (max-width: 767px) {
  // Need to be careful of the safe-area-inset for mobile...
  // This doesn't work: height: calc(100vh - env(safe-area-inset-bottom));
  .calendly-popup-content iframe {
    height: 86vh;
  }
}
@media screen and (min-width: 650px) {
  .calendly-popup-content iframe {
    top: 0px;
  }
}
@media screen and (min-width: 976px) {
  .calendly-popup-content iframe {
    top: -35.5px;
  }
}
/* DatePicker (react-dates) */
.DateRangePicker {
  @apply cursor-pointer rounded border border-gray-400;
  .DayPicker_transitionContainer {
    @apply h-30;
  }
  > div {
    @apply rounded;
  }
  &Input {
    @apply h-10 space-x-2 rounded border-none px-3;
  }
  &Input__disabled {
    @apply bg-gray-100 opacity-60;
  }
  .DateInput {
    @apply w-[100px] rounded border-none;
  }
  .DateInput input {
    @apply h-10 cursor-pointer border-none px-0 text-center text-body font-medium text-green-1;
  }
  .DateInput input.DateInput_input__focused {
    @apply border-none border-gray-400 outline-none;
  }
  .DateInput input.DateInput_input__disabled {
    @apply cursor-default bg-gray-100 not-italic;
  }
  .DateRangePickerInput_calendarIcon {
    @apply ml-0 mr-0 p-0;
  }
  .DateRangePickerInput_arrow {
    @apply mb-0.5 font-bold;
  }
}

.CalendarMonth_caption strong {
  @apply h-5 text-heading-6 font-medium text-green-5;
}

// Will edit everything selected including everything between a range of dates
.CalendarDay__selected_span {
  @apply border-green-300 bg-green-100 text-white;
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  @apply border-green-300 bg-green-1 text-white;
}

// Will edit when hovered over. _span style also has this property
.CalendarDay:hover {
  @apply border-green-300 bg-green-3;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  @apply border-green-3;
}
.DateRangePicker_picker__portal {
  @apply z-50 bg-green-5 bg-opacity-50;
  .DayPicker_portal__horizontal {
    z-index: 51;
  }
}

#ReportModalDatePicker {
  .DateRangePicker {
    @apply cursor-pointer font-normal;
  }
  .DateRangePicker.DateRangePicker_1 {
    @apply min-w-[339px];
  }
}
.recharts-tooltip-wrapper {
  outline: none;
}
.recharts-polar-grid-angle {
  transform: scale(1.01, 1.01);
  transform-origin: 38.5% 60%;
}
.recharts-polar-grid-angle line {
  @apply stroke-green-700;
  stroke-dasharray: 1;
}
.recharts-polar-grid-concentric circle {
  @apply stroke-green-100;
}
.recharts-polar-angle-axis-tick {
  font-size: 10px;
  @apply fill-green-700;
}
.recharts-polar-radius-axis-tick {
  .recharts-polar-radius-axis-tick-value {
    @apply hidden fill-green-900;
    font-size: 6px;
    transform: rotate(360deg);
    transform: translateX(-2px);
  }
}

@import "react-grid-layout/css/styles.css";
@import "react-resizable/css/styles.css";

.react-grid-item.react-grid-placeholder {
  @apply rounded-lg bg-green-900;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.ReactHotToast {
  svg {
    @apply mr-2.5;
  }
  div[role="status"] {
  }
  div > div {
    margin: 0px;
  }
}

.react-switch-disabled {
  div {
    opacity: 1 !important;
  }
  .react-switch-handle {
    background-color: white !important;
  }
  .react-switch-bg {
    background-color: #e0dfdf !important;
  }
}

/* Safari support Advisory Team */
@media screen and (min-width: 1024px) {
  _::-webkit-full-page-media,
  _:future,
  :root .safari-advisor-panel {
    margin-left: -169px !important;
  }
}
_::-webkit-full-page-media,
_:future,
:root .button.button-md {
  padding-top: 0.6rem !important;
}

@font-face {
  font-family: "password";
  font-style: "normal";
  font-weight: 400;
  font-display: block;
  src: url("https://earned-wealth-public-assets.s3.amazonaws.com/portal/password.ttf");
}

.psuedo-password {
  font-family: "password";
}
