@import "@earned/core/src/styles/scss/globals.scss";
@import "./components.scss";
@config "../../../tailwind.config.js";

/* OVERRIDES */
@layer components {
  body {
    overflow-y: auto;
  }
  .button {
    &.button {
      @apply disabled:cursor-default;
    }
    &.button-sm {
      @apply px-4 py-2 leading-none;
    }
    &.button-md {
      @apply h-10 px-4 py-2 leading-5;
    }
    &.button-lg {
      @apply px-8 py-4 leading-none;
    }
    /* TODO: move disabled styles out of other variants */
    &.button-primary {
      @apply border-green-4 hover:border-green-2 active:border-green-2 disabled:border-gray-500;
      @apply bg-green-4 hover:bg-green-2 active:bg-green-2 disabled:bg-gray-500;
      @apply text-white hover:text-white active:text-white disabled:text-white;
    }
    &.button-secondary {
      @apply border-green-700 hover:border-green-900 active:border-green-800 disabled:border-green-300;
      @apply bg-white hover:bg-white active:bg-white disabled:bg-white;
      @apply text-green-900 hover:text-green-900 active:text-green-900 disabled:text-gray-400;
    }
    &.button-tertiary {
      @apply border-none hover:border-none active:border-none disabled:border-none;
      @apply bg-transparent hover:bg-white active:bg-white disabled:bg-white;
      @apply text-green-900 hover:text-green-900 active:text-green-900 disabled:text-gray-400;
    }
  }
  .text-input-container {
    @apply font-normal;
    .input-label {
      @apply mb-2 p-0 text-body-sm text-primary;
    }
    .input {
      max-width: 400px;
      @apply h-[56px];
      @apply mb-0;
      @apply p-3;
      @apply bg-white;
      @apply text-body-xl font-normal;
      @apply text-primary;
      @apply placeholder:text-body-xl placeholder:text-gray-400;
      @apply rounded border border-gray-400;
      @apply hover:border-gray-500;
      @apply active:border-gray-600;
      @apply focus:border-gray-600;
    }
    .input-label {
      @apply text-green-3;
    }
    .input-error-label {
      @apply mt-2 h-5 text-body-xs text-danger;
    }
    .input::placeholder {
      @apply text-body-xl text-gray-400;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

svg.fadeIn {
  animation: fadeIn 0.6s ease-in;
}
