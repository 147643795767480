.ProgressBar {
  @apply relative flex items-center gap-[10px] pt-1 text-green-2;

  &Backdrop {
    @apply relative h-3 flex-1 rounded-lg bg-gray-100;
  }

  &Slider {
    @apply absolute z-10 h-3 rounded-[20px] bg-green-5 transition-width duration-300;
  }

  &Text {
    @apply whitespace-nowrap;
  }
}
