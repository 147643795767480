.AdvisoryLine {
  @apply flex flex-row items-center justify-between px-4 py-2;
  @apply hover:cursor-pointer hover:rounded-lg hover:bg-advisor-line-bg-active;
  @apply active:bg-advisor-line-bg-active;
  @apply focus:bg-advisor-line-bg-active;

  &Copy {
    @apply invisible text-link;

    &--hover {
      @apply visible;
    }
  }

  &LabelContainer {
    @apply flex w-full flex-row items-start justify-start sm:w-4/12;
  }
}
