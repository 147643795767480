@layer components {
  .SelectBox {
    @apply flex flex-col pb-6;

    &Button {
      @apply flex w-full cursor-pointer flex-row justify-between gap-2;
      @apply h-[42px] px-[11px] py-2;
      @apply text-body font-normal text-gray-800 placeholder:text-input-placeholder disabled:text-disabled;
      @apply rounded border;
      @apply border-input-primary bg-input-primary text-input-primary;
      @apply hover:border-input-primary--hover;
      @apply focus:border-input-primary--active focus:outline-none focus:ring-0;
      @apply active:border-input-primary--active;
      @apply focus:shadow-transparent;
      @apply disabled:cursor-not-allowed;
      @apply bg-white disabled:bg-disabled;
      &.--error {
        @apply border border-red-600;
      }
    }

    &Label {
      @apply pb-1 text-input-label font-normal text-green-1;

      &Required {
        @apply text-danger;
      }
    }

    &SearchInput {
      @apply overflow-visible whitespace-normal text-input-primary;
      @apply w-full border-none p-0 outline-none;
      @apply focus:ring-0;
      @apply disabled:bg-disabled;
      &.--no-selection {
        @apply text-gray-400 focus:text-input-primary;
      }
    }

    &OptionsPanelAnchor {
      @apply relative w-full;
    }

    &OptionsPanel {
      @apply rounded-lg border-none;
      @apply absolute z-20 flex max-h-[200px] w-full flex-col overflow-auto;
      @apply shadow-md;
      @apply bg-white;
      @apply py-2;
    }

    &Option {
      @apply hover:cursor-pointer hover:bg-gray-50;
      @apply active:bg-gray-50;
      @apply px-4 py-2;

      &.--disabled {
        @apply cursor-not-allowed opacity-50;
      }
      &.--selected {
        @apply bg-white;
        .SelectBoxOptionLabel {
          @apply font-medium;
        }
      }
      &.--active {
        @apply bg-gray-50;
      }
      &.--selected {
        @apply ui-active:opacity-100;
      }
    }

    &OptionLabel {
      @apply max-w-[240px] truncate text-body;
      @apply h-6;
      @apply p-0;
      @apply text-gray-800;
      @apply font-normal;
    }

    &ErrorLabel {
      @apply invisible h-6 text-input-error font-normal text-danger;
      &.--visible {
        @apply visible;
      }
    }
  }
}
