.ReviewList {
  @apply flex flex-col gap-1 pb-4;
  @apply sm:flex-row sm:justify-between;

  &Label {
    @apply sm:w-[50%] sm:pr-4;
  }

  &Value {
    @apply sm:w-[50%];
    // TODO: add truncate as an option to Typography component directly
    :first-child {
      @apply break-words;
    }
  }
}
