.UserSummary {
  @apply flex flex-col gap-2;
  @apply pb-4 pt-6;

  &Controls {
    @apply flex flex-row justify-between;
  }

  &Control {
    @apply py-2;
    @apply text-body text-link;
    @apply hover:underline;

    &Details {
      @apply flex flex-row items-center justify-center gap-1;
    }

    &Group {
      @apply flex flex-row gap-6;
    }

    &Icon {
      @apply scale-y-100 transition duration-300;

      &--expanded {
        @apply -scale-y-100;
      }
    }
  }

  &Content {
    @apply transition duration-300;
    @apply h-0 overflow-hidden opacity-0;

    &--visible {
      @apply h-fit opacity-100;
    }
  }

  &Title {
    @apply flex flex-col justify-start gap-1 sm:flex-row sm:justify-between sm:gap-0;
  }
}
