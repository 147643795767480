@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* Tokens */
  .TopNavbar {
    @apply bg-topnav-primary text-topnav-primary;
  }
}
