@font-face {
  font-family: "Summerford";
  src: url("../fonts/Summerford/font.otf") format("opentype");
}

@font-face {
  font-family: "Summerford_Italic";
  src: url("../fonts/Summerford_Italic/font.otf") format("opentype");
}

@font-face {
  font-family: "RedHat_Mono";
  src: url("../fonts/RedHat_Mono/font.ttf");
}

@font-face {
  font-family: "RedHat_Text";
  src: url("../fonts/RedHat_Text/font.ttf");
}
