@layer components {
  .Drawer {
    &Heading {
      @apply flex flex-row items-center justify-between border-t border-green-1 pt-2 text-left;
    }

    &Icon {
      @apply origin-center stroke-green-1 p-3 transition duration-300 ui-open:-scale-y-100 ui-not-open:scale-y-100;
    }

    &Panel {
      @apply pt-6;
    }
  }
}
