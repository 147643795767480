.SearchInput {
  @apply relative;

  &Icon {
    @apply absolute top-[50%] -translate-y-[50%] pl-4;
  }

  &Field {
    @apply rounded-full border border-gray-400 py-2 pl-12 pr-4;
  }
}
