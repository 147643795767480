@layer components {
  .button {
    &.button {
      @apply cursor-pointer rounded-full border border-transparent disabled:cursor-default;
    }
    &.button-sm {
      @apply px-4 py-2 leading-none;
    }
    &.button-md {
      @apply h-10 px-4 py-2 leading-5;
    }
    &.button-lg {
      @apply px-10 py-6 leading-none;
    }
    /* TODO: move disabled styles out of other variants */
    &.button-primary {
      @apply border-green-4 hover:border-green-2 active:border-green-2 disabled:border-gray-500;
      @apply bg-green-4 hover:bg-green-2 active:bg-green-2 disabled:bg-gray-500;
      @apply text-white hover:text-white active:text-white disabled:text-white;
    }
    &.button-secondary {
      @apply border-green-700 hover:border-green-900 active:border-green-800 disabled:border-green-300;
      @apply bg-white hover:bg-white active:bg-white disabled:bg-white;
      @apply text-green-900 hover:text-green-900 active:text-green-900 disabled:text-gray-400;
    }
    &.button-tertiary {
      @apply border-none hover:border-none active:border-none disabled:border-none;
      @apply bg-transparent hover:bg-white active:bg-white disabled:bg-white;
      @apply text-green-900 hover:text-green-900 active:text-green-900 disabled:text-gray-400;
    }

    &.button-text {
      @apply text-center font-medium;
    }
    &.button-text-sm {
      @apply text-body-xs;
    }

    &.button-text-md {
      @apply text-body;
    }

    &.button-text-lg {
      @apply text-body-lg;
    }

    &.button-primary-text {
      @apply text-white active:text-black disabled:text-gray-400;
    }

    &.button-primary-dark {
      @apply bg-teal-1 hover:bg-teal-2 active:bg-teal-3 disabled:bg-green-5;
    }

    &.button-primary-dark-text {
      @apply text-black disabled:text-teal-3;
    }

    &.button-secondary-text {
      @apply disabled:text-gray-400;
    }

    &.button-secondary-dark {
      @apply bg-yellow-1 hover:bg-yellow-2 active:bg-yellow-3 disabled:bg-green-5;
    }

    &.button-secondary-dark-text {
      @apply text-black disabled:text-teal-3;
    }

    &.button-tertiary-text {
      @apply text-green-1 hover:text-black active:text-black disabled:text-gray-400;
    }

    &.button-tertiary-dark {
      @apply border-teal-1 bg-transparent text-teal-1 hover:bg-teal-1 hover:text-black active:bg-teal-3 disabled:border-transparent disabled:bg-green-5 disabled:text-teal-3;
    }

    &.button-tertiary-dark-text {
      @apply text-teal-1 hover:text-black disabled:text-teal-3;
    }

    &.button-danger {
      @apply bg-red-500 hover:bg-red-600 active:bg-red-600 disabled:bg-gray-500;
      @apply border-red-500 hover:border-red-600 active:border-red-600 disabled:border-gray-500;
    }

    &.button-danger-text {
      @apply text-white hover:text-white active:text-white disabled:text-white;
    }

    &.button-disabled {
      @apply bg-gray-500 hover:bg-gray-500 active:bg-gray-500 disabled:bg-gray-500;
      @apply border-gray-500 hover:border-gray-500 active:border-gray-500 disabled:border-gray-500;
      @apply cursor-not-allowed;
    }

    &.button-disabled-text {
      @apply text-white hover:text-white active:text-white disabled:text-white;
    }
  }
}
