.Radio {
  &-root {
    @apply pb-control-pb;
    @apply h-full w-full;
    @apply rounded;
  }

  &-body {
    @apply flex flex-1 flex-row items-center gap-4;
  }

  &-input {
    @apply h-5 w-5;
    @apply border-control-primary;

    &:checked {
      @apply bg-control-primary;
    }

    &--error {
      @apply border-control-danger;
      &:checked {
        @apply bg-control-danger;
      }
    }

    &:hover {
      @apply cursor-pointer;
      @apply border-control-primary--hover;

      &:checked {
        @apply bg-control-primary--hover;
      }
    }

    &:disabled {
      @apply border-control-disabled;

      &:checked {
        @apply bg-control-disabled;
      }
    }

    &:active,
    &:focus {
      @apply border-control-primary--active;

      &:checked {
        @apply bg-control-primary--active;
      }
    }
  }

  &-label {
    @apply flex flex-1 flex-row items-center justify-between;
    @apply cursor-pointer;
    @apply text-body;
    @apply text-primary;
    @apply rounded;
  }

  &-helper-label {
    @apply pl-8 pt-input-helper-pt;
    @apply text-body font-normal text-secondary;
  }
}
