.ReviewEditLink {
  @apply flex flex-col py-2 text-link;
  @apply sm:flex-row sm:justify-between;

  &Spacer {
    @apply sm:w-[50%];
  }

  &Button {
    @apply sm:w-[50%] sm:pl-0.5;
  }
}
