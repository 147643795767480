.Checkbox {
  &-root {
    @apply max-w-[400px];
    @apply flex flex-1 flex-row items-center justify-between;
    @apply h-full w-full;
    @apply bg-green-50;
    @apply hover:bg-green-100;
    @apply rounded;
  }
  &-input {
    @apply h-6;
    position: fixed;
    opacity: 0;
    pointer-events: none;
  }
  &-label {
    @apply flex flex-1 flex-row items-center justify-between;
    @apply p-3;
    @apply cursor-pointer;
    @apply text-body-xl;
    @apply text-primary;
    @apply rounded;
  }
  &-icon {
    @apply invisible;
  }
  // Special Rules:
  &-input:checked + .Checkbox-label {
    @apply bg-green-100;
    .Checkbox-icon {
      @apply visible;
    }
  }
}
