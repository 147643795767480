.RadioGroup {
  &-root {
    @apply flex flex-col;
  }
  &ErrorLabel {
    @apply invisible text-body font-normal text-input-danger;

    &--visible {
      @apply visible;
    }
  }
}
