@layer components {
  .typography {
    &.typography-display {
      @apply text-display;
    }

    &.typography-title-* {
      @apply font-medium;
    }

    &.typography-body-* {
      @apply font-normal;
    }

    &.typography-title-2xl {
      @apply text-title-2xl;
    }

    &.typography-title-xl {
      @apply text-title-xl;
    }

    &.typography-title-lg {
      @apply text-title-lg;
    }

    &.typography-title-md {
      @apply text-title-md;
    }

    &.typography-title-sm {
      @apply text-title-sm;
    }

    &.typography-title-xs {
      @apply text-title-xs;
    }

    &.typography-body-2xl {
      @apply text-body-2xl;
    }

    &.typography-body-xl {
      @apply text-body-xl;
    }

    &.typography-body-lg {
      @apply text-body-lg;
    }

    &.typography-body-md {
      @apply text-body;
    }

    &.typography-body-sm {
      @apply text-body-sm;
    }

    &.typography-body-xs {
      @apply text-body-xs;
    }

    &.typography-gutter-lg {
      @apply pb-4;
    }

    &.typography-gutter-md {
      @apply pb-[14px];
    }

    &.typography-gutter-sm {
      @apply pb-3;
    }

    &.typography-font-sans {
      @apply font-sans;
    }

    &.typography-font-serif {
      @apply font-serif;
    }

    &.typography-font-mono {
      @apply font-mono;
    }

    &.typography-font-italic {
      @apply font-italic;
    }

    &.typography-thin {
      @apply font-thin;
    }

    &.typography-normal {
      @apply font-normal;
    }

    &.typography-medium {
      @apply font-medium;
    }

    &.typography-semibold {
      @apply font-semibold;
    }

    &.typography-text-inherit {
      @apply text-inherit;
    }

    &.typography-text-primary {
      @apply text-green-1;
    }

    &.typography-text-secondary {
      @apply text-green-4;
    }

    &.typography-text-tertiary {
      @apply text-white;
    }

    &.typography-text-quaternary {
      @apply text-teal-1;
    }

    &.typography-text-accent {
      @apply text-yellow-3;
    }

    &.typography-text-disabled {
      @apply text-gray-400;
    }

    &.typography-text-light {
      @apply text-white;
    }

    &.typography-text-link {
      @apply text-blue-600;
    }

    &.typography-text-danger {
      @apply text-red-500;
    }

    &.typography-text-black {
      @apply text-primary-black;
    }

    &.typography-text-align-inherit {
    }

    &.typography-text-align-center {
      @apply text-center;
    }

    &.typography-text-align-justify {
      @apply text-justify;
    }

    &.typography-text-align-right {
      @apply text-right;
    }

    &.typography-text-align-left {
      @apply text-left;
    }
  }
}
