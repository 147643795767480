@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* Tokens */
  .Sidebar {
    @apply bg-green-3 text-sidebar-primary;

    &Link {
      @apply hover:text-white;
      &--active {
        @apply border-white text-white;
      }
    }

    &Chat {
      @apply bg-green-3 hover:bg-green-2;
      &--active {
        @apply bg-green-2;
      }
    }

    &Dot {
      @apply bg-red-200;
    }
  }

  /* Legacy "Tokens" */
  .sidebar-section-header {
    @apply mt-4 flex min-h-[34px] flex-row items-center md:text-label-sm;
  }
  .sidebar-section-text-expanded {
    @apply opacity-100 transition-opacity duration-300;
  }
  .sidebar-section-text-collapsed {
    @apply opacity-0 transition-opacity duration-300;
  }
  .sidebar-advisor-details-expanded {
    @apply opacity-100 transition-opacity duration-300;
  }
  .sidebar-advisor-details-collapsed {
    @apply opacity-0 transition-opacity duration-300;
  }
  .sidebar-advisor-container-expanded {
    @apply mx-10 border px-4 transition-all duration-300 ease-in-out;
  }
  .sidebar-advisor-container-collapsed {
    @apply px-10 transition-all duration-300 ease-in-out;
  }
  .sidebar-logo-container-expanded {
    @apply mb-5 h-20;
  }
  .sidebar-logo-container-collapsed {
    @apply mb-3 h-20 justify-center;
  }
  .sidebar-container-expanded {
    @apply w-72 min-w-72 max-w-72 transition-all duration-300 ease-in-out;
  }
  .sidebar-container-collapsed {
    @apply w-24 min-w-24 max-w-24 transition-all duration-300 ease-in-out;
  }
  .sidebar-arrow-container-expanded {
    @apply absolute right-[2.5rem] top-[42px];
  }
  .sidebar-arrow-container-collapsed {
    @apply z-10 -mt-3 mb-1 ml-0 flex w-full flex-row justify-center;
  }
}
