@layer components {
  .TextArea {
    @apply flex flex-col pb-input-pb;

    &Input {
      @apply form-input rounded border px-3 py-2;
      @apply text-body;
      @apply border-input-primary bg-input-primary text-input-primary;
      @apply hover:border-input-primary--hover;
      @apply focus:border-input-primary--active focus:shadow-transparent focus:ring-transparent;
      @apply active:border-input-primary--active;
      @apply disabled:border-transparent disabled:bg-input-disabled disabled:text-input-disabled;
      &--error {
        @apply border-input-danger;
      }
    }

    &Input::placeholder {
      @apply text-body font-normal text-input-placeholder;
    }

    &Label {
      @apply pb-input-label-pb text-input-label font-normal text-primary;

      &--required {
        @apply text-input-danger;
      }
    }

    &ErrorLabel {
      @apply invisible  text-input-error font-normal text-input-danger;

      &--visible {
        @apply visible;
      }
    }

    &HelperText {
      @apply invisible pt-input-helper-pt text-body font-normal text-secondary;

      &--visible {
        @apply visible;
      }
    }
  }
}
