.Modal {
  @apply fixed left-0 top-0 z-50;

  &Container {
    @apply h-screen w-screen overflow-auto bg-white px-5 py-6 sm:px-0 sm:py-0;
  }

  &Panel {
    @apply m-auto max-w-[560px];
  }

  &Header {
    @apply pt-16 sm:pt-36;
  }

  &Top {
    @apply pb-2 pt-0 sm:py-2;
  }

  &TopWithTitle {
    @apply pb-6;
  }

  &Title {
    @apply flex flex-col gap-2;
  }

  &PageTitle {
    @apply flex flex-col gap-2;
  }

  &TitleCloseButton {
    @apply ml-auto h-6 w-6;
  }

  &Subtitle {
    @apply pb-8;
  }

  &TitleLogoContainer {
    @apply flex flex-row gap-2;
  }

  &ProgressBar {
    @apply pb-9 pt-3 sm:pb-9 sm:pt-2;
  }
}
